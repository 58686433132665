import './App.css';
import YouTube from 'react-youtube';

function App() {
    const videoId = 'PjHK6QP5BE0'; // Replace with your YouTube video ID

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    <b>
                        To Dulan with love Dinee
                    </b>
                </p>
                <YouTube videoId={videoId}/>

                <p>
                    <b>
                        ~ Dinee &#x2764; Dulan ~
                    </b>
                </p>
            </header>
        </div>
    );
}


export default App;
